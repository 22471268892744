import React from 'react'

import {
  Button,
  Buttons,
  Columns,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  List,
  ListItem,
  Main,
  Paragraph,
  PostponeIcon,
  Spacer,
  Stack,
  StatusBarItem,
  Strong,
  Textarea,
  XIcon
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Link } from '../../components/Link'
import { NoteItem } from '../../components/NoteItem'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { ZoomedExample } from '../../components/ZoomedExample'
import { StyledStatusBar } from '../palautteet/statusbar'
import { Color } from '../../components/Color'
import { FooterExample } from '../../examples/react/FooterExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Lomakkeen toiminnot">
    <Section title="Periaatteet">
      <List variant="unordered">
        <ListItem>
          Lomakkeella on aina oltava vähintään yksi toiminto, yleensä{' '}
          <Strong>Tallenna</Strong>/<Strong>Lähetä</Strong>/
          <Strong>Julkaise</Strong> (submit), jolla käyttäjä hyväksyy
          lomakkeelle täytetyt tiedot tai muutokset.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Panike, jolla lomakkeeseen syötetyt tiedot lähetetään tai
              tallennetaan on aina aktiivinen.
            </ListItem>
            <ListItem>
              Monisivuisella lomakkeella <Strong>Tallenna</Strong>/
              <Strong>Lähetä</Strong>/<Strong>Julkaise</Strong>-toiminto
              näytetään vain lomakkeen viimeisellä sivulla.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          Lomakkeella voi päätoiminnon lisäksi olla mieluiten{' '}
          <Strong>enintään kolme lisätoimintoa</Strong>.
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <Paragraph>
        Yleensä lomake-osion taustalaatikko erottaa toimintoalueen täytettävistä
        lomake-elementeistä, mutta jos näin ei ole toimintoealue erotetaan
        täytettävästä alueesta väliviivalla (
        <Code>
          <Link page="Divider" />
        </Code>
        ).
      </Paragraph>
      <NoteItem
        bullet="1"
        title="Lomakkeen toiminnot-alue"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            Painikkeet sijoitellaan toiminnot-aluelle keskitettynä vierekkäin.
            Painikkeiden väli (<Code>md</Code>) tulee käyttämällä{' '}
            <Code>
              <Link page="Buttons" />
            </Code>
            -komponenttia.
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Jos toimintoja on useita, lomakkeen päätoiminto sijoitetaan
                järjestyksessä oikealle.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            Toiminnot-alueelle sijoitetaan{' '}
            <Strong>enintään neljä toimintoa</Strong> samanaikaisesti.
          </ListItem>
          <ListItem>
            Yleisimpien toimintojen painiketyylit on määritelty omina
            patterneinaan, katso kunkin toiminnon patternista tarkemmin sen
            käytöstä.
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="2"
        title="Lomakkeen päätoiminto"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            Lomakkeen päätoiminto on yleensä tiedon tallentaminen palvelimmelle,
            lähettäminen käsiteltäväksi, tai esikatseleminen ja julkaiseminen.
          </ListItem>
          <ListItem>
            Lomakkeen lähettämiseen käytetään <Strong>Lähetä</Strong>-toimintoa.
            Painikkeessa käytetään <Color color="secondary" />
            -väritystä.
          </ListItem>
          <ListItem>
            Lomakkeen muokkausten tallentamiseen käytetään{' '}
            <Strong>Tallenna</Strong>-toimintoa. Painikkeessa käytetään oletus{' '}
            <Color color="primary" /> -väritystä.
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="3"
        title="Lomakkeen lisätoiminnot"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            Lomakkeen täyttämiseen liittyvät lisätoiminnot, kuten{' '}
            <Link to="/patternit/jatka-myohemmin-toiminnallisuus">
              Jatka myöhemmin
            </Link>{' '}
            tai <Link to="/patternit/keskeyta-toiminnallisuus">Keskeytä</Link>.
          </ListItem>
          <ListItem>
            Lisätoimintojen suorittamiseen käytetään toiminnon tärkeydestä
            riippuen joko <Code>&lt;Button variant="outline"&gt;</Code> tai{' '}
            <Code>&lt;Button variant="plain"&gt;</Code> -painikkeita oletus
            värillä (<Code>primary</Code>).
          </ListItem>
        </List>
      </NoteItem>
      <ZoomedExample notes={[{ id: '1' }, { id: '2' }, { id: '3' }]}>
        <Columns layout="single">
          <Main>
            <FormLayout>
              <FormLayoutItem>
                <FormGroup noMargin>
                  <Textarea label="Lorem ipsum dolor sit amet" />
                </FormGroup>
              </FormLayoutItem>
            </FormLayout>
            <Spacer marginTop="xl">
              <div data-note="1">
                <Buttons align="center">
                  <div data-note="3">
                    <Button
                      iconLeft={<XIcon />}
                      variant="plain"
                    >
                      Keskeytä
                    </Button>
                  </div>
                  <div data-note="3">
                    <Button
                      iconLeft={<PostponeIcon />}
                      variant="plain"
                    >
                      Jatka myöhemmin
                    </Button>
                  </div>
                  <div data-note="2">
                    <Button color="secondary">Lähetä</Button>
                  </div>
                </Buttons>
              </div>
            </Spacer>
          </Main>
        </Columns>
      </ZoomedExample>
    </Section>
    <Section title="Tilat ja muunnelmat">
      <SectionHeading>
        Toiminnot lomakkeella, jolla on käytössä automaattitallennus
      </SectionHeading>
      <List variant="unordered">
        <ListItem>
          Lomakkeella jolla on käytössä automaattitallennus (esimerkiksi
          Työnhakuprofiili), ei välttämättä tarvita submit-päätoimintoa. Sillä
          voi kuitenkin olla lisätoimintoja.
        </ListItem>
        <ListItem>
          Mikäli lomakkeella ei ole submit-toimintoa, pitää
          automaattitallennuksen tilasta ilmoittaa sivun alalaitaan
          sijoitettavassa{' '}
          <Code>
            <Link page="StatusBar" />
          </Code>
          -komponentissa.
        </ListItem>
      </List>
      <ZoomedExample>
        <>
          <Columns layout="single">
            <Main>
              <FormLayout>
                <FormLayoutItem>
                  <FormGroup noMargin>
                    <Textarea label="Lorem ipsum dolor sit amet" />
                  </FormGroup>
                </FormLayoutItem>
              </FormLayout>
              <Spacer marginTop="xl">
                <Buttons align="center">
                  <Button
                    iconLeft={<XIcon />}
                    variant="plain"
                  >
                    Keskeytä
                  </Button>
                  <Button
                    iconLeft={<PostponeIcon />}
                    variant="plain"
                  >
                    Jatka myöhemmin
                  </Button>
                </Buttons>
              </Spacer>
            </Main>
          </Columns>
          <StyledStatusBar saveStatus="saved">
            <StatusBarItem>
              Et ole vielä lähettänyt ilmoittautumista
            </StatusBarItem>
          </StyledStatusBar>
          <FooterExample />
        </>
      </ZoomedExample>
      <SectionHeading>Mobiilinäkymä</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Pienemmillä näytöillä lomakkeen painike-alueen muuttuessa liian
          kapeaksi, toiminnot ryhmitellään allekkain käänteisessä
          järjestyksessä, niin että päätoiminto on ensimmäisenä.
        </ListItem>
      </List>
      <ZoomedExample>
        <Spacer padding="xl">
          <Stack
            alignItems="center"
            gap="md"
          >
            <Button color="secondary">Lähetä</Button>
            <Button
              iconLeft={<PostponeIcon />}
              variant="plain"
            >
              Jatka myöhemmin
            </Button>
            <Button
              iconLeft={<XIcon />}
              variant="plain"
            >
              Keskeytä
            </Button>
          </Stack>
        </Spacer>
      </ZoomedExample>
    </Section>
    <Section title="Esimerkkejä käyttötapauksista">
      <SectionHeading>Asioinnin lomakkeet</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Asioinnin lomakkeista esim.{' '}
          <Strong>Työnhakija-asiakkaaksi ilmoittautuminen</Strong>
          -lomakkeessa on esimerkki monisivuisen lomakkeen painikkeista.
          Esimerkki yksisivuisen lomakkeen painikkeista löytyy{' '}
          <Strong>Työttömyysetuusasian selvitys</Strong> -lomakkeesta.
        </ListItem>
      </List>
      <SectionHeading>Työnhakuprofiili</SectionHeading>
      <List variant="unordered">
        <ListItem>
          <Strong>Työnhakuprofiili</Strong> on lomake, jossa ei ole
          submit-päätoimintoa.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
